import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgreementRequest, AgreementResponse, CustomerDocumentsRequestModel, CustomerDocumentsResponseModel, CustomerReferencesRequestModel } from '@fundo/app/services/types/customer';
import { environment } from '@fundo/environments/environment';
import { Observable } from 'rxjs';
export interface ContactUsModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  // IMPORTANT: This functionality was intentionally removed due to business decisions.
  // More information about the specific task can be found in [https://fundo.atlassian.net/browse/FA-2458].
  // agree: boolean;
  captcha: string;
}

@Injectable()
export class CustomerService {
  private readonly API_BASE = `${environment.apiBaseUrl}/customer`;

  constructor(
    private httpClient: HttpClient,
  ) {}

  ////// References

  public sendData(data: CustomerReferencesRequestModel[]): Observable<void> {
    return this.httpClient.post<void>(`${this.API_BASE}/references`, data);
  }

  ////// Document page

  public getCustomerData(): Observable<CustomerDocumentsResponseModel> {
    return this.httpClient.get<CustomerDocumentsResponseModel>(
      `${this.API_BASE}/debitcard/customerdata`,
    );
  }

  public sendDocuments(
    documents: CustomerDocumentsRequestModel,
  ): Observable<CustomerDocumentsResponseModel> {
    return this.httpClient.post<CustomerDocumentsResponseModel>(
      `${this.API_BASE}/additionalDocuments`,
      documents,
    );
  }

  public sendDebitCardInfo(
    debitCardInfo: CustomerDocumentsRequestModel,
  ): Observable<CustomerDocumentsResponseModel> {
    return this.httpClient.post<CustomerDocumentsResponseModel>(
      `${this.API_BASE}/debitcard`,
      debitCardInfo,
    );
  }

  /////// Agreements

  public getAgreementData(): Observable<AgreementResponse> {
    return this.httpClient.get<AgreementResponse>(
      `${this.API_BASE}/agreement/sign`,
    );
  }

  public sendAgreement(
    signature: AgreementRequest,
  ): Observable<AgreementResponse> {
    return this.httpClient.post<AgreementResponse>(
      `${this.API_BASE}/agreement/sign`,
      signature,
    );
  }

  //////// Offer service

  public getViewedAgreementStatus(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.API_BASE}/agreement/viewed`);
  }


  //////// Customers

  public getContactUs(formValue: any): Observable<ContactUsModel> {
    return this.httpClient.post<ContactUsModel>(
      `${this.API_BASE}s/contactUs`,
      formValue,
    );
  }
}
